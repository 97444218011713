/* eslint-disable quote-props */
import I18Geo from 'common/types/I18Geo';

import Belgium from './belgium.json';
import Canada from './canada.json';
import China from './china.json';
import Denmark from './denmark.json';
import France from './france.json';
import Germany from './germany.json';
import HongKong from './hong_kong.json';
import Italy from './italy.json';
import Japan from './japan.json';
import Korea from './korea.json';
import Mexico from './mexico.json';
import Netherlands from './netherlands.json';
import Norway from './norway.json';
import Singapore from './singapore.json';
import Spain from './spain.json';
import Sweden from './sweden.json';
import Switzerland from './switzerland.json';
import Taiwan from './taiwan.json';
import UnitedKingdom from './united_kingdom.json';
import UnitedStates from './united_states.json';

const COUNTRIES: I18Geo = {
  Belgium: Belgium,
  Canada: Canada,
  China: China,
  Denmark: Denmark,
  France: France,
  Germany: Germany,
  'Hong Kong': HongKong,
  Italy: Italy,
  Japan: Japan,
  Korea: Korea,
  Mexico: Mexico,
  Netherlands: Netherlands,
  Norway: Norway,
  Singapore: Singapore,
  Spain: Spain,
  Sweden: Sweden,
  Switzerland: Switzerland,
  Taiwan: Taiwan,
  'United Kingdom': UnitedKingdom,
  'United States': UnitedStates,
} as I18Geo;

export const COUNTRIES_BY_ALPHA_2: Record<string, string> = {};
Object.values(COUNTRIES).forEach(({ value, alpha_2 }) => {
  COUNTRIES_BY_ALPHA_2[alpha_2] = value;
});

export default COUNTRIES;
