import {
  CancerConditionDetails,
  Condition,
  CovidConditionDetails,
  deleteCondition,
  getConditions,
  updateCondition,
} from 'common/api/conditions';
import Profile, { MedicalDetails } from 'common/types/Profile';
import Button from 'components/Button';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';
import SearchSelectModal from 'components/SearchSelectModal';
import useIsMounted from 'hooks/useIsMounted';
import AddConditionForm from 'modules/Dashboard/components/AddConditionForm';
import ConditionsList from 'modules/Dashboard/components/ConditionsList/ConditionsList';
import DashboardPageHeading from 'modules/Dashboard/components/DashboardPageHeading';
import EditMedicalDetailsForm from 'modules/Dashboard/components/EditMedicalDetailsForm';
import SectionHeading from 'modules/Dashboard/components/SectionHeading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledMedicalInformation = styled.div``;

const StyledHeading = styled(DashboardPageHeading)`
  margin-bottom: 16px;

  @media ${(props) => props.theme.devices.tablet} {
    display: none;
  }
`;

const StyledDescription = styled.p`
  margin: 0;
`;

const StyledSection = styled.section`
  margin-top: 64px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-top: 40px;
  }
`;

const StyledSectionHeading = styled(SectionHeading)`
  margin-bottom: 24px;

  @media ${(props) => props.theme.devices.mobile} {
    margin-bottom: 20px;
  }
`;

const StyledConditionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

const StyledAddAnotherPrompt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  button {
    min-width: 232px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    button {
      width: 100%;
    }
  }
`;

const StyledAddAnotherConditionForm = styled(AddConditionForm)`
  padding-top: 64px;
  border-top: 1px solid ${(props) => props.theme.colors.headerBackgroundDark};
`;

const StyledModalContent = styled.div`
  padding-bottom: 20px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-align: center;
`;

const StyledModalControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
`;

const StyledError = styled.div`
  ${(props) => props.theme.dashboardContent}
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fontSizes.h3};
`;

export type MedicalInformationProps = {
  medicalDetails: MedicalDetails;
  onProfileUpdated: (profile: Profile) => void;
  theme?: DefaultTheme;
};

const MedicalInformation = ({
  medicalDetails,
  onProfileUpdated,
  ...rest
}: MedicalInformationProps) => {
  const { t } = useTranslation();
  const [conditions, setConditions] = useState<Condition[] | null>(null);
  const [areConditionsChecked, setAreConditionsChecked] =
    useState<boolean>(false);
  const [errorKey, setErrorKey] = useState<string | null>(null);
  const [removalId, setRemovalId] = useState<number | null>(null);
  const [isAddConditionShown, setIsAddConditionShown] =
    useState<boolean>(false);
  const [isSearchModalShown, setIsSearchModalShown] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const onConditionAdded = async (condition: Condition) => {
    setIsAddConditionShown(false);
    setConditions(conditions ? [...conditions, condition] : [condition]);
  };

  const onRemoveCondition = (id: number) => {
    setRemovalId(id);
  };

  const onUpdateConditionDetails = async (
    id: number,
    details: CancerConditionDetails | CovidConditionDetails,
  ) => {
    try {
      await updateCondition(id, details);
      if (!isMounted.current) {
        return;
      }
      const response = await getConditions();
      if (!isMounted.current) {
        return;
      }
      setConditions(response.conditions);
    } catch (ex) {
      if (!isMounted.current) {
        return false;
      }
      setErrorKey('common:unknown_error');
    }
  };

  const onConfirmRemoval = async () => {
    try {
      if (!removalId) {
        setErrorKey('common:unknown_error');
        return;
      }
      await deleteCondition(removalId);
      if (!isMounted.current) {
        return;
      }
      const updatedConditions =
        conditions?.filter(({ id }) => id !== removalId) ?? null;
      setConditions(updatedConditions);
    } catch (ex) {
      if (!isMounted.current) {
        return;
      }
      setErrorKey('common:unknown_error');
    }
    onCancelRemoval();
  };

  const onCancelRemoval = () => {
    setRemovalId(null);
  };

  useEffect(() => {
    const loadConditions = async () => {
      try {
        setErrorKey(null);
        const response = await getConditions();
        if (!isMounted.current) {
          return;
        }
        setConditions(response.conditions);
        setAreConditionsChecked(true);
      } catch (ex) {
        setErrorKey('common:unknown_error');
      }
    };
    loadConditions();
  }, [isMounted]);

  if (errorKey) {
    return (
      <StyledMedicalInformation>
        <StyledError>{t(errorKey)}</StyledError>
      </StyledMedicalInformation>
    );
  }

  return (
    <StyledMedicalInformation {...rest}>
      <StyledHeading>{t('profile:medical_information')}</StyledHeading>
      <StyledDescription>
        {t('profile:medical_information_description')}
      </StyledDescription>
      <StyledSection>
        <StyledSectionHeading>
          {t('profile:my_medical_history')}
        </StyledSectionHeading>
        <EditMedicalDetailsForm
          currentMedicalDetails={medicalDetails}
          onProfileUpdated={onProfileUpdated}
        />
      </StyledSection>

      <StyledSection>
        <StyledSectionHeading>
          {t('profile:my_conditions')}
        </StyledSectionHeading>
        {conditions && conditions.length > 0 ? (
          <StyledConditionsSection>
            <ConditionsList
              conditions={conditions}
              onRemoveCondition={onRemoveCondition}
              onUpdateConditionDetails={onUpdateConditionDetails}
            />
            {isAddConditionShown ? (
              <StyledAddAnotherConditionForm
                conditions={conditions ?? []}
                onConditionAdded={onConditionAdded}
              />
            ) : (
              <StyledAddAnotherPrompt>
                <Button onClick={() => setIsAddConditionShown(true)}>
                  {t('profile:add_another_condition')}
                </Button>
                <Button onClick={() => setIsSearchModalShown(true)}>
                  {t('common:search_trials_title_case')}
                </Button>
              </StyledAddAnotherPrompt>
            )}
          </StyledConditionsSection>
        ) : (
          <AddConditionForm
            conditions={conditions ?? []}
            onConditionAdded={onConditionAdded}
          />
        )}
      </StyledSection>

      <LoadingOverlay isShown={!areConditionsChecked} />
      {removalId && (
        <Modal
          heading={t('profile:remove_medical_condition_title')}
          controls={
            <StyledModalControls>
              <Button onClick={onCancelRemoval}>{t('common:cancel')}</Button>
              <Button dark onClick={onConfirmRemoval}>
                {t('common:remove')}
              </Button>
            </StyledModalControls>
          }
          small
        >
          <StyledModalContent>
            {t('profile:remove_medical_condition_description')}
          </StyledModalContent>
        </Modal>
      )}
      {isSearchModalShown && (
        <SearchSelectModal onClose={() => setIsSearchModalShown(false)} />
      )}
    </StyledMedicalInformation>
  );
};

export default MedicalInformation;
