import { PATHS } from 'common/constants';
import Profile from 'common/types/Profile';
import DropdownNavigation from 'components/DropdownNavigation';
import TabNavigation from 'components/TabNavigation';
import { Route, Switch } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import EditProfile from './modules/EditProfile';
import MedicalInformation from './modules/MedicalInformation';
import NotificationSettings from './modules/NotificationSettings';
import ProfileSettings from './modules/ProfileSettings';

import iconMedicalImg from './assets/icon-medical.svg';
import iconNotificationImg from './assets/icon-notification.svg';
import iconProfileImg from './assets/icon-profile.svg';
import iconSettingsImg from './assets/icon-settings.svg';

// Styled Components
const StyledProfile = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledProfileContainer = styled.div`
  ${(props) => props.theme.dashboardContent}
  padding-top: 80px;
  padding-bottom: 80px;

  // Special resolution to avoid squishing on some tablets
  @media (max-width: 1360px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const StyledProfileCard = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 24px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
`;

const StyledProfileNav = styled.div`
  flex: 0 0 auto;
  width: 320px;
  padding-top: 80px;
  padding-bottom: 80px;

  // Special resolution to avoid squishing on some tablets
  @media (max-width: 1360px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media ${(props) => props.theme.devices.tablet} {
    display: none;
  }
`;

const StyledMobileProfileNav = styled(DropdownNavigation)`
  display: none;
  margin-bottom: 40px;
  z-index: 80;

  @media ${(props) => props.theme.devices.tablet} {
    display: block;
  }
`;

const StyledProfileContent = styled.div`
  flex: 1 1 auto;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  padding: 80px;
  border-left: 1px solid ${(props) => props.theme.colors.divider};
  overflow: hidden;

  // Special resolution to avoid squishing on some tablets
  @media (max-width: 1360px) {
    padding: 40px;
  }

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px 15px;
  }
`;

export type MyProfileProps = {
  profile: Profile;
  onProfileUpdated: (profile: Profile) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const MyProfile = ({ profile, onProfileUpdated, ...rest }: MyProfileProps) => {
  const navItems = [
    {
      icon: iconProfileImg,
      textKey: 'dashboard:my_profile',
      path: PATHS.DASHBOARD_PROFILE,
    },
    {
      icon: iconMedicalImg,
      textKey: 'dashboard:medical_information',
      path: PATHS.DASHBOARD_PROFILE_MEDICAL,
    },
    {
      icon: iconSettingsImg,
      textKey: 'dashboard:profile_settings',
      path: PATHS.DASHBOARD_PROFILE_SETTINGS,
    },
    {
      icon: iconNotificationImg,
      textKey: 'dashboard:notification_settings',
      path: PATHS.DASHBOARD_PROFILE_NOTIFICATIONS,
    },
  ];

  return (
    <StyledProfile {...rest}>
      <StyledProfileContainer>
        <StyledMobileProfileNav navItems={navItems} />
        <StyledProfileCard>
          <StyledProfileNav>
            <TabNavigation isVertical navItems={navItems} />
          </StyledProfileNav>
          <StyledProfileContent>
            <Switch>
              <Route
                path={PATHS.DASHBOARD_PROFILE_MEDICAL}
                render={() => (
                  <MedicalInformation
                    medicalDetails={profile.medical_details}
                    onProfileUpdated={onProfileUpdated}
                  />
                )}
              />
              <Route
                path={PATHS.DASHBOARD_PROFILE_SETTINGS}
                render={() => <ProfileSettings />}
              />
              <Route
                path={PATHS.DASHBOARD_PROFILE_NOTIFICATIONS}
                render={() => (
                  <NotificationSettings
                    profile={profile}
                    onProfileUpdated={onProfileUpdated}
                  />
                )}
              />
              <Route
                render={() => (
                  <EditProfile
                    profile={profile}
                    onProfileUpdated={onProfileUpdated}
                  />
                )}
              />
            </Switch>
          </StyledProfileContent>
        </StyledProfileCard>
      </StyledProfileContainer>
    </StyledProfile>
  );
};

export default MyProfile;
