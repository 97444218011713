import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components/macro';

import TrialUIFilters from 'common/types/TrialUIFilters';

import Button from 'components/Button';
import LocationSelect from 'components/LocationSelect';
import Modal from 'components/Modal';
import SectionSubheading from 'components/SectionSubheading';
import TextButton from 'components/TextButton';

import AgeInput from 'components/AgeInput';
import { AgeOption } from 'components/AgeInput/AgeInput';
import ConditionSelect from 'modules/Search/components/ConditionSelect';
import GenderSelect from 'modules/Search/components/GenderSelect';

import { CONDITIONS, GENDERS, PHASES } from 'modules/Search/constants';

import { IS_INTEGRATOR_MODE } from 'common/constants';
import collapseImg from './assets/icon-collapse.svg';
import expandImg from './assets/icon-expand.svg';

const FILTER_SELECTS = {
  CONDITION: 'CONDITION',
  PHASE: 'PHASE',
  LOCATION: 'LOCATION',
  AGE: 'AGE',
  GENDER: 'GENDER',
} as Record<string, string>;

// Styled Components
const StyledResultsFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: relative;

  padding: 25px 20px;
  margin-bottom: 30px;

  background-color: ${(props) => props.theme.colors.altSearchBackground};

  z-index: 90;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;

    padding: 20px 15px;

    background-color: ${(props) => props.theme.colors.background};
    box-shadow: 0 0 5px 0 ${(props) => props.theme.rawColors.black20};
  }
`;

const StyledFiltersContainer = styled.div<{ isExpanded?: boolean }>`
  @media ${(props) => props.theme.devices.mobile} {
    display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 54px;
    left: 0;
    right: 0;

    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;

    background-color: ${(props) => props.theme.colors.background};
    box-shadow: 0 10px 10px 0 ${(props) => props.theme.rawColors.black20};
  }
`;

const StyledFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledFiltersTitleContainer = styled.button`
  display: none;

  @media ${(props) => props.theme.devices.mobile} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 0;

    background-color: transparent;
    border: none;
  }
`;

const StyledFiltersTitle = styled(SectionSubheading)`
  display: none;

  @media ${(props) => props.theme.devices.mobile} {
    display: block;
    align-self: flex-start;
    margin: 0;
  }
`;

const StyledFiltersSummary = styled.div`
  display: none;

  @media ${(props) => props.theme.devices.mobile} {
    display: block;
    align-self: flex-start;
    padding-top: 10px;
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
`;

const StyledFilter = styled.button`
  height: 46px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;

  padding-left: 18px;
  padding-right: 18px;

  background-color: ${(props) => props.theme.colors.searchBackground};
  border-radius: 25px;
  border: 2px solid ${(props) => props.theme.colors.active};

  box-shadow: 0 0 10px 0 ${(props) => props.theme.rawColors.black20};

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

const StyledUpdateButton = styled(Button)`
  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
  }
`;

type ExpandedButtonProps = {
  isExpanded?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

// Extract 'isExpanded' to avoid passing it to the dom anchor element
// eslint-disable-next-line react/prop-types, react/button-has-type
const ExpandedExtractedButton = ({
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isExpanded,
  ...rest
}: ExpandedButtonProps) => <button {...rest}>{children}</button>;
const StyledStartOver = styled(ExpandedExtractedButton)`
  margin-left: 20px;

  background: none;
  border: none;

  color: ${(props) => props.theme.colors.link};
  line-height: 18px;
  white-space: nowrap;

  @media ${(props) => props.theme.devices.mobile} {
    margin-left: 0;
    padding-top: 20px;

    font-size: ${(props) => props.theme.fontSizes.small};
  }
`;

const StyledDoneFilterButton = styled(Button)<{ isExpanded?: boolean }>`
  display: none;

  @media ${(props) => props.theme.devices.mobile} {
    display: ${(props) => (props.isExpanded ? 'block' : 'none')};
    width: 160px;
    margin-top: 40px;
  }
`;

const StyledModalControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
    margin: 0 15px;
  }
`;

const StyledCancelButton = styled(TextButton)`
  margin-top: 30px;
`;

export type ResultsFiltersProps = {
  onFiltersUpdate: (filters: TrialUIFilters) => void;
  onStartOver: () => void;
  theme?: DefaultTheme;
} & TrialUIFilters;

// Component
const ResultsFilters = ({
  conditionSearch = '',
  conditionGroups,
  conditions,
  phases,
  country,
  state,
  age,
  genders,

  onFiltersUpdate,
  onStartOver,
}: ResultsFiltersProps) => {
  const { t } = useTranslation(['search']);

  const [activeFilterSelect, setActiveFilterSelect] = useState<string | null>(
    null,
  );
  const [isExpanded, setIsExpanded] = useState(false);

  // Condition selection
  const [conditionStep, setConditionStep] = useState<number>(0);
  const [conditionSearchValue, setConditionSearchValue] = useState<string>('');
  const [selectedConditionGroups, setSelectedConditionGroups] = useState<
    string[]
  >([]);
  const [selectedConditions, setSelectedConditions] = useState<string[]>([]);

  // Phase Selection
  const [selectedPhases, setSelectedPhases] = useState<string[]>([]);

  // Location Selection
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedState, setSelectedState] = useState<string>();

  // Age Selection
  const [selectedAge, setSelectedAge] = useState<number | undefined>();

  // Gender Selection
  const [selectedGenders, setSelectedGenders] = useState<string[]>([]);

  // Needed to pass to age dropdown (react-select) to avoid overflow issues
  const [filterModalDiv, setFilterModalDiv] = useState<HTMLDivElement | null>(
    null,
  );

  const nextConditonStep = () => {
    setConditionStep(1);
  };

  const updateSelection = () => {
    onFiltersUpdate({
      conditionSearch: conditionSearchValue,
      conditionGroups: selectedConditionGroups,
      conditions: selectedConditions,
      phases: selectedPhases,
      country: selectedCountry,
      state: selectedState,
      age: selectedAge,
      genders: selectedGenders,
    });
    setActiveFilterSelect(null);
  };

  const showFilter = (filterControl: string) => {
    // Reset the values
    setConditionStep(0);
    setConditionSearchValue(conditionSearch);
    setSelectedConditionGroups(conditionGroups);
    setSelectedConditions(conditions);
    setSelectedPhases(phases);
    setSelectedCountry(country);
    setSelectedState(state);
    setSelectedAge(age);
    setSelectedGenders(genders);

    // Show the control
    setActiveFilterSelect(filterControl);
  };

  // Define controls for each filter
  const filterControls = {
    // Step #1: Condition
    [FILTER_SELECTS.CONDITION]: (
      <ConditionSelect
        hideIcons={IS_INTEGRATOR_MODE}
        searchValue={conditionSearchValue}
        selectedConditions={selectedConditions}
        selectedGroups={selectedConditionGroups}
        step={conditionStep}
        onConditionsChange={setSelectedConditions}
        onGroupsChange={setSelectedConditionGroups}
        onSearch={updateSelection}
        onSearchChange={setConditionSearchValue}
        hideTitle
      />
    ),

    // Step #2: Location
    [FILTER_SELECTS.LOCATION]: (
      <LocationSelect
        selectedCountry={selectedCountry}
        selectedState={selectedState}
        onStateChange={setSelectedState}
        onCountryAndStateChange={(updatedCountry, updatedState) => {
          setSelectedCountry(updatedCountry);
          setSelectedState(updatedState);
        }}
      />
    ),

    // Step #3: Age Group
    [FILTER_SELECTS.AGE]: (
      <AgeInput
        menuPortalTarget={filterModalDiv}
        value={selectedAge}
        includeBlank
        onChange={(newValue) => setSelectedAge((newValue as AgeOption)?.value)}
      />
    ),

    // Step #4: Gender
    [FILTER_SELECTS.GENDER]: (
      <GenderSelect
        selectedGenders={selectedGenders}
        onGendersChange={setSelectedGenders}
      />
    ),
  };

  return (
    <StyledResultsFilters>
      <StyledFiltersTitleContainer onClick={() => setIsExpanded(!isExpanded)}>
        <StyledFiltersTitle>{t('search:filters')}</StyledFiltersTitle>
        <img src={isExpanded ? collapseImg : expandImg} alt="" />
      </StyledFiltersTitleContainer>
      <StyledFiltersSummary>
        {[
          conditionSearch ||
            (conditions.length > 0
              ? conditions
                  .map((condition) => t(CONDITIONS[condition].i18nKey))
                  .join(', ')
              : t('search:all_conditions')),
          phases.length > 0
            ? phases.map((phase) => t(PHASES[phase].i18nKey)).join(', ')
            : t('search:all_phases'),
          state || country || t('search:all_locations'),
          age ? age : t('search:all_ages'),
          genders.length > 0
            ? genders.map((gender) => t(GENDERS[gender].i18nKey)).join(', ')
            : t('search:all_genders'),
        ].join(', ')}
      </StyledFiltersSummary>
      <StyledFiltersContainer isExpanded={isExpanded}>
        <StyledFilters>
          <StyledFilter onClick={() => showFilter(FILTER_SELECTS.CONDITION)}>
            {conditionSearch ||
              (conditions.length > 0
                ? conditions
                    .map((condition) => t(CONDITIONS[condition].i18nKey))
                    .join(', ')
                : t('search:all_conditions'))}
          </StyledFilter>
          <StyledFilter onClick={() => showFilter(FILTER_SELECTS.LOCATION)}>
            {state || country || t('search:all_locations')}
          </StyledFilter>
          <StyledFilter onClick={() => showFilter(FILTER_SELECTS.AGE)}>
            {age ? age : t('search:all_ages')}
          </StyledFilter>
          <StyledFilter onClick={() => showFilter(FILTER_SELECTS.GENDER)}>
            {genders.length > 0
              ? genders.map((gender) => t(GENDERS[gender].i18nKey)).join(', ')
              : t('search:all_genders')}
          </StyledFilter>
        </StyledFilters>
        <StyledDoneFilterButton
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(false)}
          dark
        >
          {t('common:done')}
        </StyledDoneFilterButton>
      </StyledFiltersContainer>

      <StyledStartOver type="button" onClick={onStartOver}>
        {t('search:start_over')}
      </StyledStartOver>

      {activeFilterSelect && (
        <Modal
          controls={
            <StyledModalControls>
              {
                // If the condition group select is active and one is selected, the action is 'Next'
                activeFilterSelect === FILTER_SELECTS.CONDITION &&
                conditionStep === 0 &&
                selectedConditionGroups.length > 0 ? (
                  <StyledUpdateButton onClick={nextConditonStep} dark>
                    {t('common:next')}
                  </StyledUpdateButton>
                ) : (
                  <StyledUpdateButton onClick={updateSelection} dark>
                    {t('search:update_selection')}
                  </StyledUpdateButton>
                )
              }
              <StyledCancelButton onClick={() => setActiveFilterSelect(null)}>
                {t('common:cancel')}
              </StyledCancelButton>
            </StyledModalControls>
          }
          // This is a workaround to pass the modal div to the age select (react-select)
          // Storing a reference in state should generally be avoided
          ref={(modalRef) => setFilterModalDiv(modalRef)}
        >
          {filterControls[activeFilterSelect]}
        </Modal>
      )}
    </StyledResultsFilters>
  );
};

export default ResultsFilters;
