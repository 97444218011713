export default {
  'all_conditions': 'All conditions',
  'all_countries': 'All Countries',
  'all_phases': 'All phases',
  'all_locations': 'All locations',
  'all_ages': 'All ages',
  'all_genders': 'All genders',
  'all_states': 'All States/Provinces',

  'age_eighteen_to_sixty_four': '18 - 64',
  'age_sixty_five_plus': '65+',
  'age_title': 'What is your age group?',
  'age_zero_to_seventeen': '0 - 17',

  'browser_title': 'Heal Mary - Search',

  'condition_bile_duct': 'Bile Duct',
  'condition_bile_duct_liver': 'Bile Duct, Liver',
  'condition_breast': 'Breast',
  'condition_breast_esophagus_lung': 'Breast, Esophagus, Lung',
  'condition_colon': 'Colon',
  'condition_colon_rectal': 'Colon, Rectal',
  'condition_esophagus': 'Esophagus',
  'condition_example': 'Example: breast cancer',
  'condition_groups_title': 'Let’s start with what brought you here.',
  'condition_head': 'Head',
  'condition_head_neck_throat': 'Head, Neck, Throat',
  'condition_leukemia': 'Leukemia',
  'condition_leukemia_lymphoma_melanoma': 'Leukemia, Lymphoma, Melanoma',
  'condition_liver': 'Liver',
  'condition_lung': 'Lung',
  'condition_lymphoma': 'Lymphoma',
  'condition_melanoma': 'Melanoma',
  'condition_neck': 'Neck',
  'condition_ovarian': 'Ovarian',
  'condition_ovarian_pancreatic_prostate': 'Ovarian, Pancreatic, Prostate',
  'condition_pancreatic': 'Pancreatic',
  'condition_prostate': 'Prostate',
  'condition_rectal': 'Rectal',
  'condition_throat': 'Throat',
  'condition_title': 'You selected {{groups}}. Which one would you like to look into?',

  'disclaimer': '<0>Our platform is intended to connect you to trials based on your personal selected criteria. The studies listed may not have been evaluated by the U.S. Federal Government. We encourage you speak with your doctor/health care professional about risks and benefits of clinical trials before applying. Please see our <0>FAQ</0>.<0>',

  'error_loading_trials': 'There was an error loading the trials, please try again.',

  'filters': 'Filters',
  'how_would_you_like_to_search_cancer_trials': 'How would you like to search for trials?',

  'loading_results': 'Loading results...',
  'lookup_my_location': 'Automatically lookup my location',
  'location_lookup_error': 'Error determining your location. Please manually select your location.',
  'location_title': 'Share your location to find trials near you.',

  'more_details': 'More details',
  'more_results_connect': ', ... ',
  'next_page': 'Next Page',
  'no_results': 'We couldn\'t find any trials that matched your selection needs in our database but there could be other options. Please consult your doctor/practitioner or feel free to contact us for future matching.',
  'or_select_any': 'or select any of the following',

  'page_of_pages': 'Page {{page}} of {{pages}}',
  'phases_title': 'Which phase is the illness?',
  'phase_early_phase_1': 'Early Phase 1',
  'phase_1': 'Phase 1',
  'phase_2': 'Phase 2',
  'phase_3': 'Phase 3',
  'phase_4': 'Phase 4',
  'previous_page': 'Previous Page',

  'gender_title': 'Are you:',
  'gender_male': 'Male',
  'gender_female': 'Female',

  'going_to_ask_questions': 'We\'re going to ask you a few questions to find trials that meet your needs.',

  'results_browser_title': 'Heal Mary - Trials Results',
  'results_count': 'Search results for {{count}} study',
  'results_count_plural': 'Search results for {{count}} studies',
  'search_conditions_or_diseases': 'Search conditions or diseases',
  'search': 'Search',
  'search_using_my_medical_information': 'Search using my Medical Information',
  'select_which_condition_to_search_with': 'Select which of your conditions you would like to search with',
  'select_your_country': 'Select your Country',
  'share_results_with_others': 'Share this results page with others',
  'share_results_title': 'Heal Mary Search Results',
  'skip_this_question': 'Skip this question',
  'state_province': 'State/Province',
  'start_a_new_search': 'Start a new search',
  'start_over': 'Start Over',
  'step_of_steps': 'Step {{step}}/{{steps}}',

  'results_title': 'Based on your selection, here’s clinical trials we’ve found for you to check out so far',
  'results_title_from_medical': 'Using your Medical Information, here are the results for cancer trials',

  'unsupported_location': 'It appears that you are located in an unsupported location ({{location}}).',
  'update_selection': 'Update Selection',
};
