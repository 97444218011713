import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';

// Styled Components
const StyledIconGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.mobile} {
    justify-content: space-around;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 21%;
  margin-top: 64px;

  text-align: center;

  span {
    margin-top: 32px;
  }

  @media ${(props) => props.theme.devices.tablet} {
    margin-top: 56px;
    font-size: ${(props) => props.theme.fontSizes.small};

    span {
      margin-top: 24px;
      line-height: 20px;
    }

    img {
      width: 64px;
    }
  }

  @media ${(props) => props.theme.devices.mobile} {
    width: 42%;
    margin-top: 48px;

    img {
      width: 56px;
    }
  }
`;

export type Icon = {
  image: string;
  text: string;
};

export type IconGridProps = {
  icons: Icon[];
  theme?: DefaultTheme;
};

// Component
const IconGrid = ({ icons }: IconGridProps) => (
  <StyledIconGrid>
    {icons.map((icon) => (
      <StyledIcon key={icon.image}>
        <img src={icon.image} alt="" />
        <span>{icon.text}</span>
      </StyledIcon>
    ))}
  </StyledIconGrid>
);

export default IconGrid;
