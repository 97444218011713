export enum GenderEnum {
  male = 'male',
  female = 'female',
  intersex = 'intersex',
}

export enum GenderIdentityEnum {
  preferNotToSay = 'prefer_not_to_say',
  agender = 'agender',
  androgyne = 'androgyne',
  bigender = 'bigender',
  butch = 'butch',
  cisgender = 'cisgender',
  genderExpansive = 'gender_expansive',
  genderfluid = 'genderfluid',
  genderOutlaw = 'gender_outlaw',
  genderqueer = 'genderqueer',
  masculineOfCenter = 'masculine_of_center',
  nonbinary = 'nonbinary',
  omnigender = 'omnigender',
  polygenderAndPangender = 'polygender_and_pangender',
  transgender = 'transgender',
  trans = 'trans',
  twoSpirit = 'two_spirit',
}

export enum UserTypeEnum {
  caregiver = 'caregiver',
  patient = 'patient',
  practitioner = 'practitioner',
}

export enum EthnicIdentityEnum {
  americanIndianOrAlaskanNative = 'american_indian_or_alaskan_native',
  asian = 'asian',
  blackOrAfricanAmerican = 'black_or_african_american',
  hispanicOrLatino = 'hispanic_or_latino',
  nativeHawaiianOrOtherPacificIslander = 'native_hawaiian_or_other_pacific_islander',
  white = 'white',
}

export enum MethodOfContactEnum {
  phone = 'phone',
  email = 'email',
  text = 'text',
}

export enum NotificationTypeEnum {
  trialsMatchingHealth = 'trials_matching_health',
  trialsSubscribedSearches = 'trials_subscribed_searches',
  newFeatures = 'new_features',
}

export enum LanguagesEnum {
  albanian = 'albanian',
  arabic = 'arabic',
  armenian = 'armenian',
  basque = 'basque',
  bengali = 'bengali',
  bosnian = 'bosnian',
  bulgarian = 'bulgarian',
  catalan = 'catalan',
  chineseSimplified = 'chinese_simplified',
  chineseTraditional = 'chinese_traditional',
  croatian = 'croatian',
  czech = 'czech',
  danish = 'danish',
  dutch = 'dutch',
  english = 'english',
  estonian = 'estonian',
  finnish = 'finnish',
  french = 'french',
  georgian = 'georgian',
  german = 'german',
  greek = 'greek',
  hebrew = 'hebrew',
  hindi = 'hindi',
  hungarian = 'hungarian',
  icelandic = 'icelandic',
  indonesian = 'indonesian',
  irish = 'irish',
  italian = 'italian',
  japanese = 'japanese',
  korean = 'korean',
  kurdish = 'kurdish',
  latvian = 'latvian',
  lithuanian = 'lithuanian',
  macedonian = 'macedonian',
  malay = 'malay',
  malayalam = 'malayalam',
  norwegian = 'norwegian',
  persian = 'persian',
  polish = 'polish',
  portugueseBrazilian = 'portuguese_brazilian',
  portugueseIberian = 'portuguese_iberian',
  punjabi = 'punjabi',
  romanian = 'romanian',
  russian = 'russian',
  serbian = 'serbian',
  slovak = 'slovak',
  slovenian = 'slovenian',
  spanish = 'spanish',
  swahili = 'swahili',
  swedish = 'swedish',
  tagalog = 'tagalog',
  tamil = 'tamil',
  telugu = 'telugu',
  thai = 'thai',
  vietnamese = 'vietnamese',
  welsh = 'welsh',
}

export enum MedicalDetailsEnum {
  inClinicalTrial = 'in_clinical_trial',
  previousClinicalTrial = 'previous_clinical_trial',
  haveImmuneDiseases = 'have_immune_diseases',
  takingImmunosuppressants = 'taking_immunosuppressants',
  breastfeeding = 'breastfeeding',
}

export type MedicalDetails = Partial<Record<MedicalDetailsEnum, boolean>>;

type Profile = {
  first_name: string;
  last_name: string;
  gender_biological?: GenderEnum;
  gender_identity?: GenderIdentityEnum;
  ethnic_identity?: string[];
  user_type: UserTypeEnum;
  age: number;
  medical_details: MedicalDetails;
  phone: string;
  email: string;
  preferred_contact_method: MethodOfContactEnum[];
  preferred_contact_time: string;
  preferred_languages: string[];
  notification_types: NotificationTypeEnum[];
  address_street?: string;
  address_secondary?: string;
  address_country_alpha_2?: string;
  address_state?: string;
  address_city?: string;
  address_postal_code?: string;
};

export default Profile;
