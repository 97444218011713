import { checkIsSignedIn } from 'common/api/auth';
import React, { ReactNode, useEffect, useState } from 'react';

export type AuthContextState = {
  isAuthChecked: boolean;
  isAuthenticated: boolean;
  setIsAuthChecked: (isAuthChecked: boolean) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

export const DEFAULT_AUTH_CONTEXT_VALUE: AuthContextState = {
  isAuthChecked: false,
  isAuthenticated: false,
  setIsAuthChecked: () => {},
  setIsAuthenticated: () => {},
};

export const AuthContext = React.createContext(DEFAULT_AUTH_CONTEXT_VALUE);

export type AuthContextWrapperProps = {
  children: ReactNode;
};

export const AuthContextWrapper = ({ children }: AuthContextWrapperProps) => {
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const checkSession = async () => {
      const isSignedIn = await checkIsSignedIn();
      setIsAuthenticated(isSignedIn);
      setIsAuthChecked(true);
    };

    checkSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthChecked,
        isAuthenticated,
        setIsAuthChecked,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
