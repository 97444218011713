/* eslint-disable react/prop-types */
import LabeledField from 'components/LabeledField';
import { LabeledFieldProps } from 'components/LabeledField/LabeledField';
import MultiSelect from 'components/MultiSelect';
import { MultiSelectProps } from 'components/MultiSelect/MultiSelect';
import React from 'react';
import { DefaultTheme } from 'styled-components/macro';

// Styled Components

export type LabeledMultiSelectProps = {
  theme?: DefaultTheme;
} & LabeledFieldProps &
  MultiSelectProps;

const LabeledMultiSelect = React.forwardRef<
  HTMLLabelElement,
  LabeledMultiSelectProps
>(
  (
    { hasError, isRequired, label, tooltip, ...rest }: LabeledMultiSelectProps,
    ref,
  ) => (
    <LabeledField
      hasError={hasError}
      label={label}
      isRequired={isRequired}
      tooltip={tooltip}
      ref={ref}
    >
      <MultiSelect hasError={hasError} {...rest} />
    </LabeledField>
  ),
);

export default LabeledMultiSelect;
