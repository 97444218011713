import { Auth } from '@aws-amplify/auth';
import EmailDetails from 'common/types/EmailDetails';
import getClientId from 'common/util/getClientId';
import { BASE_URL } from './constants';
import authenticatedFetch from './util/authenticatedFetch';

export type AuthError = {
  code: string;
  name: string;
};

export type AuthResult = {
  error?: AuthError;
  success: boolean;
};

export type UserResponse = {
  uid: string;
  email: string;
  last_login: string;
};

/**
 * Helper function for building common clientMetadata passed to auth calls
 * @returns {object} clientMetadata structure
 */
function getClientMetadata() {
  return {
    integratorClientId: getClientId() ?? '',
  };
}

/**
 * Sign In a user
 * @param {string} email The email to sign in with
 * @param {string} password The users password
 * @returns {object} If sign in was successful and any error: { success: boolean, error: string }
 */
export async function signIn(
  email: string,
  password: string,
): Promise<AuthResult> {
  try {
    await Auth.signIn(email, password, getClientMetadata());

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Sign Up a new user
 * @param {string} email The email to sign up with
 * @param {string} password The password to use
 * @returns {object} If sign up was successful and any error: { success: boolean, error: string }
 */
export async function signUp(
  email: string,
  password: string,
): Promise<AuthResult> {
  try {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
      clientMetadata: getClientMetadata(),
    });

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Send link to reset password
 * @param email The user's email address
 * @returns {object} If sending was successful and any error: { success: boolean, error: string }
 */
export async function forgotPassword(email: string) {
  try {
    await Auth.forgotPassword(email, getClientMetadata());

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Reset password using a code provided by the forgot email function
 * @param code The reset verification code
 * @param email The user's email address
 * @param password The new password
 * @returns {object} If sending was successful and any error: { success: boolean, error: string }
 */
export async function resetForgotPassword(
  code: string,
  email: string,
  password: string,
) {
  try {
    await Auth.forgotPasswordSubmit(email, code, password, getClientMetadata());

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Change the current user's email
 * @param {string} email The new password to use
 * @returns {object} If the change was successful and any error: { success: boolean, error: string }
 */
export async function changeEmail(email: string) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { email }, getClientMetadata());

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Change the current user's password
 * @param {string} currentPassword The user's current password
 * @param {string} newPassword The new password to use
 * @returns {object} If the change was successful and any error: { success: boolean, error: string }
 */
export async function changePassword(
  currentPassword: string,
  newPassword: string,
) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(
      user,
      currentPassword,
      newPassword,
      getClientMetadata(),
    );

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Verify a user's updated email
 * @param {string} newPassword The new password to use
 * @returns {object} If verification was successful and any error: { success: boolean, error: string }
 */
export async function verifyEmailChange(code: string) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.verifyUserAttributeSubmit(user, 'email', code);

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Resend the email verification to the user (for email change)
 * @returns {object} If resending was successful and any error: { success: boolean, error: string }
 */
export async function resendEmailVerification() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.verifyUserAttribute(user, 'email', getClientMetadata());

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Resend the email verification to the user (for initial sign up)
 * @param email The user's email address
 * @returns {object} If resending was successful and any error: { success: boolean, error: string }
 */
export async function resendVerification(email: string) {
  try {
    await Auth.resendSignUp(email, getClientMetadata());

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Verify a user's email
 * @param {string} email Email of user to verify
 * @param {string} code Verification code
 * @returns {object} If verification was successful and any error: { success: boolean, error: string }
 */
export async function verifyEmail(
  email: string,
  code: string,
): Promise<AuthResult> {
  try {
    await Auth.confirmSignUp(email, code, {
      clientMetadata: getClientMetadata(),
    });

    return {
      success: true,
    };
  } catch (ex) {
    return {
      error: ex as AuthError,
      success: false,
    };
  }
}

/**
 * Validate sign in was successful with the backend API
 * @returns {boolean} If there is an active signed in session
 */
export async function validateSignIn(): Promise<UserResponse> {
  const clientId = getClientId();
  const response = await authenticatedFetch(
    `${BASE_URL}/signin${clientId ? `?integratorClientId=${clientId}` : ''}`,
  );
  return response.json();
}

/**
 * Sign out the current user
 * @returns {Promise<any>} The result of signing out
 */
export async function signOut() {
  return Auth.signOut();
}

/**
 * Check if there is an active signed in session
 * @returns {boolean} If there is an active signed in session
 */
export async function checkIsSignedIn() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user !== undefined && user !== null;
  } catch (ex) {
    return false;
  }
}

/**
 * Get the current user's auth email
 * @returns {Promise<EmailDetails|null>} The user's email, null on failure
 */
export async function getAuthEmailDetails(): Promise<EmailDetails | null> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (!user) {
      return null;
    }

    const { email, email_verified } = user.attributes as Record<
      string,
      string | boolean
    >;
    return { email: email as string, isVerified: email_verified as boolean };
  } catch (ex) {
    return null;
  }
}
