import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

import ButtonLink from 'components/ButtonLink';
import Divider from 'components/Divider';
import IconGrid from 'components/IconGrid';
import ImageBanner from 'components/ImageBanner';
import PageHeader from 'components/PageHeader';
import SubscribeForm from 'components/SubscribeForm';

import searchHeroImg from './assets/search_hero.jpg';
import communityImg from './assets/community.jpg';
import decisionImg from './assets/decision.jpg';
import missionImg from './assets/mission.jpg';

import freeImg from './assets/icon-free.svg';
import saveTimeImg from './assets/icon-savetime.svg';
import globalDatabaseImg from './assets/icon-globaldatabase.svg';
import upToDateImg from './assets/icon-up_to_date.svg';
import easilyAccessImg from './assets/icon-acceptreject.svg';
import accessAvailableImg from './assets/icon-clinicaltrial.svg';
import increaseMatchesImg from './assets/icon-healthcare.svg';
import aiImg from './assets/icon-speech.svg';

// Styled Components
const StyledHome = styled.main``;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  a {
    display: block;

    margin-left: 20px;
    margin-right: 20px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;

    a:nth-of-type(2) {
      margin-top: 30px;
    }
  }
`;

const StyledStatsContainer = styled.div`
  display: flex;
  padding-top: 52px;
  padding-bottom: 72px;
  align-items: center;

  @media ${(props) => props.theme.devices.mobile} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const StyledStats = styled.section`
  ${(props) => props.theme.paddedContent}

  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;

  flex-grow: 1;
  width: 100%; /* IE11 Fix for centering */
  max-width: 990px;

  margin: 0 auto;

  @media ${(props) => props.theme.devices.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledStat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 1; /* IE11 Fix for overflow */

  width: 100%; /* IE11 Fix for overflow */
  max-width: 250px;

  text-align: center;

  @media ${(props) => props.theme.devices.mobile} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const StyledStatValue = styled.div`
  color: ${(props) => props.theme.rawColors.blue};
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 60px;

  @media ${(props) => props.theme.devices.mobile} {
    font-size: 50px;
  }
`;

const StyledPercent = styled.span`
  font-size: 40px;
`;

const StyledStatDescription = styled.div`
  max-width: 100%; /* IE11 Fix for overflow */
  margin-top: 10px;
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeights.bold};

  @media ${(props) => props.theme.devices.mobile} {
    font-size: 18px;
  }
`;

const StyledDetailsSections = styled.div`
  padding: 0 7.6%;

  background-color: ${(props) => props.theme.rawColors.offWhite};

  @media ${(props) => props.theme.devices.mobile} {
    ${(props) => props.theme.paddedContent}
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const StyledDetailsSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-top: 90px;
  padding-bottom: 90px;

  @media ${(props) => props.theme.devices.mobile} {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    text-align: center;
  }
`;

const StyledDetailsImage = styled.img<{ left?: boolean }>`
  width: 44%;
  order: ${(props) => (props.left ? 1 : 2)};

  @media ${(props) => props.theme.devices.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 12px;
    order: 1;
  }
`;

const StyledDetailsText = styled.div<{ left?: boolean }>`
  width: 49%;
  order: ${(props) => (props.left ? 1 : 2)};

  & > p {
    max-width: 100%; /* IE11 Fix for overflow */
  }

  @media ${(props) => props.theme.devices.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    order: 1;
  }
`;

const StyledWhy = styled.div`
  ${(props) => props.theme.paddedContent}
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1232px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: ${(props) => props.theme.colors.background};

  text-align: center;

  hr {
    margin-top: 8px;
    margin-bottom: 10px;
  }

  @media ${(props) => props.theme.devices.mobile} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const StyledMissionHeading = styled.h1`
  margin-bottom: 20px;

  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.regular};
`;

export type HomeProps = {
  theme: DefaultTheme;
};

// Component
const Home = ({ theme }: HomeProps) => {
  const { t } = useTranslation(['home']);
  const whyIcons = [
    {
      text: t('home:why_free'),
      image: freeImg,
    },
    {
      text: t('home:why_save_time'),
      image: saveTimeImg,
    },
    {
      text: t('home:why_global'),
      image: globalDatabaseImg,
    },
    {
      text: t('home:why_up_to_date'),
      image: upToDateImg,
    },
    {
      text: t('home:why_easily_access'),
      image: easilyAccessImg,
    },
    {
      text: t('home:why_access_available'),
      image: accessAvailableImg,
    },
    {
      text: t('home:why_increase_matches'),
      image: increaseMatchesImg,
    },
    {
      text: t('home:why_ai'),
      image: aiImg,
    },
  ];

  return (
    <StyledHome>
      <Helmet>
        <title>{t('home:browser_title')}</title>
      </Helmet>
      <PageHeader
        backgroundImage={searchHeroImg}
        color={theme.colors.lightText}
      >
        <h1>{t('home:welcome')}</h1>
        <h2>{t('home:are_you_here_for_covid_or_other')}</h2>
        <StyledButtons>
          <ButtonLink to="/covid" dark>
            {t('common:covid_trials')}
          </ButtonLink>
          <ButtonLink to="/search" dark>
            {t('common:cancer_trials')}
          </ButtonLink>
        </StyledButtons>
      </PageHeader>
      <PageHeader backgroundColor={theme.rawColors.beige}>
        <h1>{t('home:title')}</h1>
      </PageHeader>

      <StyledStatsContainer>
        <StyledStats>
          <StyledStat>
            <StyledStatValue>
              <Trans i18nKey="home:problems_percent">
                <span />
                <StyledPercent />
              </Trans>
            </StyledStatValue>
            <StyledStatDescription>
              {t('home:problems_description')}
            </StyledStatDescription>
          </StyledStat>

          <StyledStat>
            <StyledStatValue>
              <Trans i18nKey="home:enrollment_percent">
                <span />
                <StyledPercent />
              </Trans>
            </StyledStatValue>
            <StyledStatDescription>
              {t('home:enrollment_description')}
            </StyledStatDescription>
          </StyledStat>

          <StyledStat>
            <StyledStatValue>
              <span>{t('home:trials_count')}</span>
            </StyledStatValue>
            <StyledStatDescription>
              {t('home:trials_description')}
            </StyledStatDescription>
          </StyledStat>
        </StyledStats>
      </StyledStatsContainer>

      <StyledDetailsSections>
        <StyledDetailsSection>
          <StyledDetailsImage src={communityImg} alt="" />
          <StyledDetailsText>
            <h2>{t('home:free_for_patients_title')}</h2>
            <Divider />
            <Trans i18nKey="home:free_for_patients_description">
              <p />
            </Trans>
          </StyledDetailsText>
        </StyledDetailsSection>

        <StyledDetailsSection>
          <StyledDetailsImage src={decisionImg} alt="" />
          <StyledDetailsText left>
            <h2>{t('home:decisions_title')}</h2>
            <Divider />
            <Trans i18nKey="home:decisions_description">
              <p />
            </Trans>
          </StyledDetailsText>
        </StyledDetailsSection>
      </StyledDetailsSections>
      <StyledWhy>
        <h1>{t('home:why_use_heal_mary')}</h1>
        <Divider width={160} />
        <IconGrid icons={whyIcons} />
      </StyledWhy>

      <ImageBanner backgroundImage={missionImg} color={theme.colors.lightText}>
        <StyledMissionHeading>{t('home:our_mission')}</StyledMissionHeading>
        <h2>{t('home:mission_statement')}</h2>
      </ImageBanner>
      <SubscribeForm />
    </StyledHome>
  );
};

export default Home;
