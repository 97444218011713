export default {
  'all_locations': 'All locations offering this clinical trial',
  'back_to_results': '< Back to search results',
  'check_my_eligibility': 'Check my eligibility for this trial',
  'conditions_label': 'Conditions:',
  'contact_clinical_trial': 'Contact clinical trial',
  'detailed_description': 'Detailed Description',
  'eligibility_criteria': 'Eligibility Criteria',
  'extension': 'ext.',
  'email_label': 'Email: ',
  'error_loading_trial': 'There was an error loading the trial, please try again.',
  'general_contact': 'General Contact for this clinical trial',
  'interested_in_participating': 'Interested in participating in this clinical trial?',
  'interventions_label': 'Interventions:',
  'like_trial': 'Like Trial',
  'liked_trial': 'Liked Trial',
  'unlike_trial': 'Unlike Trial',
  'loading_trial': 'Loading Trial...',
  'locations_label': 'Locations:',
  'locations_with_contacts': 'All locations with available contacts',
  'objective_label': 'Objective:',
  'plus_more': '(+{{count}} more)',
  'phone_label': 'Phone: ',
  'resource_links': 'Resource Links:',
  'share_this_page': 'Share this page with others',
  'sponsored_trial': 'SPONSORED TRIAL',
  'status': 'Status',
  'type': 'Type',
  'view_all_locations': 'View all locations',
  'go_now': 'Go Now',
  'please_hold': 'Please hold on. We\'re sending you to another site to sign up. \nIf you would like to stay on this site, please click on Cancel.',
};
