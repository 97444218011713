import React from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import PropTypes from 'prop-types';

// Styled Components
const StyledImageBanner = styled.div<{
  backgroundColor?: string;
  backgroundImage?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 134px;
  padding-bottom: 134px;

  background-color: ${(props) => props.backgroundColor};
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
`;

const StyledContent = styled.div<{ color?: string }>`
  ${(props) => props.theme.paddedContent};
  max-width: 960px;
  margin-left: 10%;
  margin-right: 10%;

  color: ${(props) => props.color};

  h1,
  h2,
  h3,
  p {
    color: ${(props) => props.color};
  }

  @media ${(props) => props.theme.devices.tablet} {
    margin-left: 8%;
    margin-right: 8%;
  }

  @media ${(props) => props.theme.devices.mobile} {
    margin-left: 0;
    margin-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export type ImageBannerProps = {
  backgroundColor?: string;
  backgroundImage?: string;
  children: React.ReactNode;
  color?: string;
  theme?: DefaultTheme;
};

// Component
const ImageBanner = ({
  backgroundColor,
  backgroundImage,
  children,
  color,
}: ImageBannerProps) => (
  <StyledImageBanner
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
  >
    <StyledContent color={color}>{children}</StyledContent>
  </StyledImageBanner>
);

ImageBanner.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  color: PropTypes.string,
};

ImageBanner.defaultProps = {
  backgroundColor: null,
  backgroundImage: null,
  color: null,
};

export default ImageBanner;
