import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import {
  EVENT_CLOSE_SURVEY,
  EVENT_SUBMIT,
  EVENT_CLICK,
  EVENT_INCOMPLETE,
  gtmPush,
} from 'common/analytics/gtm';
import { ELIGIBILITY_VALUES, ELIGIBILITY_RESULTS } from 'common/constants';
import EligibilityChecklistItem from 'common/types/EligibilityChecklistItem';

import Button from 'components/Button';
import EligibilityCheckList from 'components/EligibilityCheckList';
import Modal from 'components/Modal';
import TextButton from 'components/TextButton';
import EligibilityResultModal from 'components/EligibilityResultModal';
import TrialApplicationModal from 'components/TrialApplicationModal';
import TrialLocation from 'common/types/TrialLocation';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

// Styled Components
const StyledModalSubheading = styled.div<{ hasError: boolean }>`
  ${(props) =>
    props.hasError &&
    css`
      color: ${props.theme.colors.error};
    `}
`;

const StyledModalSubheadingSmall = styled.div`
  padding-top: 10px;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.subheading};

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.xsmall};
  }
`;

const StyledInclusionList = styled.div``;

const StyledExclusionList = styled.div``;

const StyledFormControls = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  text-align: center;

  button {
    width: 290px;

    @media ${(props) => props.theme.devices.mobile} {
      width: 100%;
    }
  }
`;

export type EligibilityCheckModalProps = {
  inclusionCriteria: string[];
  exclusionCriteria: string[];
  inclusionValues: string[];
  exclusionValues: string[];
  isSponsored?: boolean;
  locations?: TrialLocation[];
  trialId: string;
  onClose: () => void;
  theme?: DefaultTheme;
};

// Component
const EligibilityCheckModal = ({
  inclusionCriteria,
  exclusionCriteria,
  inclusionValues,
  exclusionValues,
  isSponsored = false,
  locations = [],
  trialId,

  onClose,
}: EligibilityCheckModalProps) => {
  const { t } = useTranslation(['trialEligibility']);
  const pageLocation = useLocation();
  const { showApplication = false } = qs.parse(pageLocation.search, {
    ignoreQueryPrefix: true,
  });

  const initialInclusionItems = inclusionCriteria.map((text, index) => ({
    text,
    value: inclusionValues[index] || null,
    hasError: false,
  }));
  const initialExclusionItems = exclusionCriteria.map((text, index) => ({
    text,
    value: exclusionValues[index] || null,
    hasError: false,
  }));

  // Used for analytics purposes
  const eligibilityItemCount =
    (inclusionCriteria || []).length + (exclusionCriteria || []).length;

  const [inclusionItems, setInclusionItems] = useState<
    EligibilityChecklistItem[]
  >(initialInclusionItems);
  const [exclusionItems, setExclusionItems] = useState<
    EligibilityChecklistItem[]
  >(initialExclusionItems);
  const [inclusionHasError, setInclusionHasError] = useState<boolean>(false);
  const [exclusionHasError, setExclusionHasError] = useState<boolean>(false);
  const [result, setResult] = useState<string | null>(null);
  const [isResultShown, setIsResultShown] = useState<boolean>(false);
  const [isApplicationShown, setIsApplicationShown] = useState<boolean>(
    showApplication == 'true' || false,
  );
  const [isConfirmCloseShown, setIsConfirmCloseShown] =
    useState<boolean>(false);

  const inclusionItemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const exclusionItemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleOnClose = () => {
    gtmPush({
      event: EVENT_CLICK,
      surveyName: trialId,
      surveyQuestions: eligibilityItemCount,
      eventAction: EVENT_CLOSE_SURVEY,
    });
    onClose();
  };

  // Check user's eligibility based on their inclusion/exclusion selections
  const onCheck = () => {
    let missingInclusion = false;
    let hasUnsure = false;
    let hasExclusion = false;
    let updatedInclusionHasError = false;
    let updatedExclusionHasError = false;

    // Check for missing inclusion results and mark them as having an error
    const updatedInclusionItems = inclusionItems.map((inclusionItem, index) => {
      const { value } = inclusionItem;

      if (!value) {
        // Scroll only to the first error item
        if (!updatedInclusionHasError && inclusionItemRefs.current[index]) {
          inclusionItemRefs.current[index]!.scrollIntoView({
            behavior: 'smooth',
          });
        }

        updatedInclusionHasError = true;
        return {
          ...inclusionItem,
          hasError: true,
        };
      }

      hasUnsure = hasUnsure || value === ELIGIBILITY_VALUES.UNSURE;
      missingInclusion = missingInclusion || value === ELIGIBILITY_VALUES.NO;

      return inclusionItem;
    });

    // Check for missing exclusion results and mark them as having an error
    const updatedExclusionItems = exclusionItems.map((exclusionItem, index) => {
      const { value } = exclusionItem;

      if (!value) {
        // Scroll only to the first error item
        if (
          !updatedInclusionHasError &&
          !updatedExclusionHasError &&
          exclusionItemRefs.current[index]
        ) {
          exclusionItemRefs.current[index]!.scrollIntoView({
            behavior: 'smooth',
          });
        }

        updatedExclusionHasError = true;
        return {
          ...exclusionItem,
          hasError: true,
        };
      }

      hasUnsure = hasUnsure || value === ELIGIBILITY_VALUES.UNSURE;
      hasExclusion = hasExclusion || value === ELIGIBILITY_VALUES.YES;

      return exclusionItem;
    });

    setInclusionHasError(updatedInclusionHasError);
    setExclusionHasError(updatedExclusionHasError);
    setInclusionItems(updatedInclusionItems);
    setExclusionItems(updatedExclusionItems);

    if (updatedInclusionHasError || updatedExclusionHasError) {
      gtmPush({
        event: EVENT_CLICK,
        surveyName: trialId,
        surveyQuestions: eligibilityItemCount,
        eventAction: EVENT_SUBMIT,
        eventStatus: EVENT_INCOMPLETE,
      });
      return;
    }

    // No missing inclusion, no exclusion, and no unsure - get in touch
    let eligibilityResult = ELIGIBILITY_RESULTS.MAY_QUALIFY;
    if (missingInclusion || hasExclusion) {
      // Missing inclusion / has exclusion - may not qualify
      eligibilityResult = ELIGIBILITY_RESULTS.MAY_NOT_QUALIFY;
    } else if (hasUnsure) {
      // Unsure - speak with doctor
      eligibilityResult = ELIGIBILITY_RESULTS.UNSURE;
    }

    setResult(eligibilityResult);
    setIsResultShown(true);

    gtmPush({
      event: EVENT_CLICK,
      surveyName: trialId,
      surveyQuestions: eligibilityItemCount,
      eventAction: EVENT_SUBMIT,
      eventLabel: eligibilityResult,
    });
  };

  const onExclusionItemsChange = (items: EligibilityChecklistItem[]) => {
    setExclusionItems(items);

    // For exclusion items, check if there are any YES or UNSURE responses
    // and if so, short circuit the form and immediately show the result
    const itemWithYes = items.find(
      ({ value }) => value === ELIGIBILITY_VALUES.YES,
    );
    const itemWithUnsure = items.find(
      ({ value }) => value === ELIGIBILITY_VALUES.UNSURE,
    );

    if (itemWithUnsure) {
      setResult(ELIGIBILITY_RESULTS.UNSURE);
      setIsResultShown(true);
    } else if (itemWithYes) {
      setResult(ELIGIBILITY_RESULTS.MAY_NOT_QUALIFY);
      setIsResultShown(true);
    }
  };

  const onInclusionItemsChange = (items: EligibilityChecklistItem[]) => {
    setInclusionItems(items);

    // For inclusion items, check if there are any UNSURE responses
    // and if so, short circuit the form and immediately show the result
    const itemWithUnsure = items.find(
      ({ value }) => value === ELIGIBILITY_VALUES.UNSURE,
    );
    if (itemWithUnsure) {
      setResult(ELIGIBILITY_RESULTS.UNSURE);
      setIsResultShown(true);
    }
  };

  return (
    <Modal
      heading={t('trialEligibility:check_my_eligibility')}
      subheading={
        <>
          <StyledModalSubheading
            hasError={inclusionHasError || exclusionHasError}
          >
            {t('trialEligibility:please_fill_out')}
          </StyledModalSubheading>
          {(exclusionItems ? exclusionItems.length : 0) +
            (inclusionItems ? inclusionItems.length : 0) >
            20 && (
            <StyledModalSubheadingSmall>
              {t('trialEligibility:checklist_can_take_minutes', {
                minutes: 15,
              })}
            </StyledModalSubheadingSmall>
          )}
        </>
      }
      controls={
        <TextButton
          onClick={() => {
            const hasInclusionChange =
              inclusionItems.find(({ value }) => value !== null) !== undefined;
            const hasExclusionChange =
              exclusionItems.find(({ value }) => value !== null) !== undefined;

            if (hasInclusionChange || hasExclusionChange) {
              setIsConfirmCloseShown(true);
            } else {
              handleOnClose();
            }
          }}
        >
          {t('common:close')}
        </TextButton>
      }
    >
      {exclusionItems && exclusionItems.length > 0 && (
        <StyledExclusionList>
          <EligibilityCheckList
            id="exclusion"
            items={exclusionItems}
            // Required specifically for analytics
            eligibilityItemCount={eligibilityItemCount}
            trialId={trialId}
            onChange={onExclusionItemsChange}
            refFunc={(index, ref) => {
              exclusionItemRefs.current[index] = ref;
            }}
          />
        </StyledExclusionList>
      )}

      {inclusionItems && inclusionItems.length > 0 && (
        <StyledInclusionList>
          <EligibilityCheckList
            id="inclusion"
            items={inclusionItems}
            // Required specifically for analytics
            eligibilityItemCount={eligibilityItemCount}
            trialId={trialId}
            onChange={onInclusionItemsChange}
            refFunc={(index, ref) => {
              inclusionItemRefs.current[index] = ref;
            }}
          />
        </StyledInclusionList>
      )}

      <StyledFormControls>
        <Button dark onClick={onCheck}>
          {t('trialEligibility:check_eligibility')}
        </Button>
      </StyledFormControls>

      {isResultShown && result && (
        <EligibilityResultModal
          isSponsored={isSponsored}
          result={result}
          onClose={() => setIsResultShown(false)}
          onShowApply={() => {
            setIsResultShown(false);
            setIsApplicationShown(true);
          }}
        />
      )}

      {isApplicationShown && (
        <TrialApplicationModal
          exclusionResults={exclusionItems.map(({ text, value }) => ({
            text,
            value,
          }))}
          inclusionResults={inclusionItems.map(({ text, value }) => ({
            text,
            value,
          }))}
          isSponsored={isSponsored}
          locations={locations}
          trialId={trialId}
          onClose={() => setIsApplicationShown(false)}
        />
      )}

      {isConfirmCloseShown && (
        <ConfirmationModal
          depth={2}
          prompt={t('trialEligibility:selections_will_be_lost')}
          title={t('trialEligibility:confirm_close')}
          small
          onCancel={() => setIsConfirmCloseShown(false)}
          onConfirm={handleOnClose}
        />
      )}
    </Modal>
  );
};

export default EligibilityCheckModal;
