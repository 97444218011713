import React, { useEffect, useState, useRef } from 'react';
import styled, { DefaultTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

import areFiltersEqual from 'common/util/areFiltersEqual';
import getFirstValue from 'common/util/getFirstValue';
import TrialSearchResponse from 'common/types/TrialSearchResponse';

import PageController from 'components/PageController/PageController';
import ResultsControls from 'components/ResultsControls';
import ResultsList from 'components/ResultsList/ResultsList';
import SharePageControl from 'components/SharePageControl';
import TrialResultsLoadingError from 'components/TrialResultsLoadingError';

import backImg from './assets/icon-back.svg';
import nextImg from './assets/icon-next.svg';

// Styled Components
const StyledSearchPage = styled.div``;

const StyledPreSearchContainer = styled.div`
  ${(props) => props.theme.paddedContent}
  max-width: 800px;
  min-height: 270px;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 64px;
  text-align: center;
`;

const StyledSearchContent = styled.div`
  ${(props) => props.theme.paddedContent}

  position: relative;

  max-width: 800px;
  min-height: 270px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 104px;

  text-align: center;
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const StyledStepCount = styled.div`
  margin-top: 40px;

  color: ${(props) => props.theme.colors.accentText};
  font-size: ${(props) => props.theme.fontSizes.small};
`;

const StyledControls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
  margin-bottom: 40px;
`;

const StyledNavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;

  border: none;
  background-color: transparent;

  color: ${(props) => props.theme.colors.link};

  &.next {
    img {
      margin-left: 10px;
    }
  }

  &.back {
    img {
      margin-right: 10px;
    }
  }

  &.skip {
    text-decoration: underline;
  }
`;

const StyledResults = styled.div`
  padding-top: 80px;
  padding-bottom: 40px;
  background-color: ${(props) => props.theme.colors.searchBackground};
`;

const StyledSharePageControl = styled(SharePageControl)`
  margin-bottom: 38px;

  @media ${(props) => props.theme.devices.mobile} {
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledSearchResultsContent = styled.div`
  ${(props) => props.theme.paddedContent}

  max-width: 800px;
  margin: 0 auto;

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledResultsListContainer = styled.div`
  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledPageControls = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 40px;

  @media ${(props) => props.theme.devices.mobile} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StyledDisclaimer = styled.div`
  margin-top: 60px;
  font-size: ${(props) => props.theme.fontSizes.small};

  @media ${(props) => props.theme.devices.mobile} {
    ${(props) => props.theme.paddedContent}
  }
`;

const StyledResultsMessage = styled.div`
  ${(props) => props.theme.paddedContent}

  margin-top: -20px;
  margin-bottom: 80px;
  text-align: center;

  button {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export type SearchFilters = {
  conditions?: string[];
  conditionSearch?: string;
  country?: string;
  page?: number;
  state?: string;
};

export type SearchPageProps = {
  currentStep: number;
  disclaimer: React.ReactNode;
  filters: SearchFilters;
  filterControls?: React.ReactNode;
  hasPreSearchControls?: boolean;
  preSearchControls?: React.ReactNode;
  searchTitle: string;
  resultsTitle: string;
  stepControls: React.ReactNode[];
  title: string;
  trialBaseUrl: string;
  isStepWithNext: (step: number) => boolean;
  isStepWithSkip: (step: number) => boolean;
  onBack: () => void;
  onNext: () => void;
  onPageSelect: (page: number) => void;
  searchTrials: (filters: SearchFilters) => Promise<TrialSearchResponse>;
  theme?: DefaultTheme;
};

// Component
const SearchPage = ({
  currentStep,
  disclaimer,
  filters,
  filterControls,
  hasPreSearchControls = false,
  preSearchControls,
  resultsTitle,
  searchTitle,
  stepControls,
  title,
  trialBaseUrl,

  isStepWithNext,
  isStepWithSkip,
  onBack,
  onNext,
  onPageSelect,
  searchTrials,
}: SearchPageProps) => {
  const { t } = useTranslation();

  const [trialResults, setTrialResults] = useState<TrialSearchResponse>({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<any>(null);

  const currentFilters = useRef<SearchFilters>({});

  // Search trials when the filters change
  useEffect(() => {
    const loadTrials = async () => {
      try {
        setIsLoading(true);
        setLoadingError(null);
        const results = await searchTrials(filters);
        // If the filters have changed since searching, ignore the results
        // to avoid inconsistent filters to results
        if (filters !== currentFilters.current) {
          return;
        }
        setTrialResults(results);
      } catch (ex) {
        setLoadingError(ex);
      } finally {
        setIsLoading(false);
      }
    };

    // Find the first value that has changed
    const firstChangedValue = Object.entries(filters).find(
      ([key, value]) =>
        !areFiltersEqual(
          value,
          currentFilters.current[key as keyof SearchFilters],
        ),
    );

    // Only search if a changed value is found
    if (firstChangedValue) {
      currentFilters.current = filters;
      loadTrials();
    }
  });

  const { data = [], pages, total } = trialResults;

  return (
    <StyledSearchPage>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {preSearchControls && (
        <StyledPreSearchContainer>{preSearchControls}</StyledPreSearchContainer>
      )}

      {!preSearchControls && currentStep < stepControls.length && (
        <StyledSearchContent>
          <StyledTitle>{searchTitle}</StyledTitle>
          <StyledStepCount>
            {t('search:step_of_steps', {
              step: currentStep + 1,
              steps: stepControls.length,
            })}
          </StyledStepCount>

          {stepControls[currentStep]}

          <StyledControls>
            {(currentStep !== 0 || hasPreSearchControls) && (
              <StyledNavButton className="back" type="button" onClick={onBack}>
                <img src={backImg} alt="" />
                {t('common:back')}
              </StyledNavButton>
            )}

            {isStepWithNext(currentStep) && (
              <StyledNavButton className="next" type="button" onClick={onNext}>
                {t('common:next')}
                <img src={nextImg} alt="" />
              </StyledNavButton>
            )}

            {isStepWithSkip(currentStep) && (
              <StyledNavButton className="skip" type="button" onClick={onNext}>
                {t('search:skip_this_question')}
              </StyledNavButton>
            )}
          </StyledControls>
        </StyledSearchContent>
      )}

      <StyledResults>
        <StyledSearchResultsContent>
          {currentStep >= stepControls.length && (
            <>
              <StyledResultsMessage>
                <h2>{resultsTitle}</h2>
              </StyledResultsMessage>
              {filterControls}
            </>
          )}

          <StyledSharePageControl
            title={t('search:share_results_with_others')}
            shareTitle={t('search:share_results_title')}
            shareDescription={t('search:share_results_title')}
          />

          <ResultsControls
            isLoading={isLoading}
            loadingError={loadingError}
            page={filters.page}
            pages={pages}
            total={total}
            onPageSelect={onPageSelect}
          />

          {loadingError ? (
            <TrialResultsLoadingError />
          ) : (
            <StyledResultsListContainer>
              <ResultsList
                conditionSearch={
                  filters.conditionSearch ??
                  (filters.conditions
                    ? getFirstValue(filters.conditions)
                    : undefined)
                }
                countrySearch={filters.country}
                isLoading={isLoading}
                results={data}
                stateSearch={filters.state}
                trialBaseUrl={trialBaseUrl}
              />
            </StyledResultsListContainer>
          )}

          <StyledPageControls>
            <PageController
              page={filters.page ?? 0}
              pageCount={pages}
              onPageSelect={onPageSelect}
            />
          </StyledPageControls>

          <StyledDisclaimer>{disclaimer}</StyledDisclaimer>
        </StyledSearchResultsContent>
      </StyledResults>
    </StyledSearchPage>
  );
};

export default SearchPage;
