import CheckboxOption from 'components/CheckboxOption';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import dropdownArrowImg from './assets/dropdown-arrow.svg';

// Styled Components
const StyledMultiSelect = styled.div``;

const StyledDropdownButton = styled.button<{
  hasError: boolean;
  isExpanded?: boolean;
}>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 15px;
  padding-right: 48px;

  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: ${(props) => (props.isExpanded ? '5px 5px 0 0' : '5px')};

  background-image: url(${dropdownArrowImg});
  background-repeat: no-repeat;
  background-position: right -30px center;
  background-origin: content-box;

  text-align: left;
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  line-height: normal;

  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
    `}

  > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledDropdownList = styled.ul<{ isExpanded?: boolean }>`
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 50vh;
  overflow-y: auto;

  margin: 0;
  padding: 8px 20px;

  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 10px 10px -5px ${(props) => props.theme.rawColors.black20};

  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${(props) => props.theme.fontSizes.regular};

  list-style: none;
  z-index: 10;

  li {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-radius: 5px;

  box-shadow: 0 0 10px -5px ${(props) => props.theme.rawColors.black20};

  @media ${(props) => props.theme.devices.mobile} {
    font-size: ${(props) => props.theme.fontSizes.small};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.active};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export type MultiSelectOption = {
  label: string;
  value: string;
};

export type MultiSelectProps = {
  defaultValue?: string;
  disabled?: boolean;
  hasError?: boolean;
  options: MultiSelectOption[];
  otherValue?: string;
  showOther?: boolean;
  values: string[];
  onOtherChange?: (value: string) => void;
  onValuesChange: (values: string[]) => void;
  theme?: DefaultTheme;
} & React.ComponentPropsWithoutRef<'div'>;

const MultiSelect = ({
  defaultValue = '',
  disabled = false,
  hasError = false,
  showOther = true,
  options,
  otherValue = '',
  values,
  onOtherChange = () => {},
  onValuesChange,
  ...rest
}: MultiSelectProps) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isOtherChecked, setIsOtherChecked] = useState<boolean>(
    otherValue !== '',
  );
  const containerRef = useOnClickOutside<HTMLDivElement>(() =>
    setIsExpanded(false),
  );

  const selectedOptions = options.filter(({ value }) => values.includes(value));
  const selectedOptionsLabels = selectedOptions.map(({ label }) => label);
  if (otherValue) {
    selectedOptionsLabels.push(otherValue);
  }

  return (
    <StyledMultiSelect ref={containerRef} {...rest}>
      <StyledDropdownButton
        disabled={disabled}
        hasError={hasError}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>
          {selectedOptionsLabels && selectedOptionsLabels.length > 0
            ? selectedOptionsLabels.join(', ')
            : defaultValue || <>&nbsp;</>}
        </span>
      </StyledDropdownButton>
      <StyledDropdownList isExpanded={isExpanded}>
        {options.map(({ label, value }) => (
          <li key={value}>
            <CheckboxOption
              label={label}
              value={value}
              checked={values.includes(value)}
              onChange={(evt) => {
                if (evt.target.checked) {
                  onValuesChange([...values, value]);
                } else {
                  onValuesChange(
                    values.filter((itemValue) => itemValue !== value),
                  );
                }
              }}
            />
          </li>
        ))}
        {showOther && (
          <li>
            <CheckboxOption
              label={t('common:other')}
              checked={isOtherChecked}
              onChange={(evt) => {
                setIsOtherChecked(evt.target.checked);
                if (!evt.target.checked) {
                  onOtherChange('');
                }
              }}
            />
            {isOtherChecked && (
              <StyledInput
                value={otherValue}
                onChange={(evt) => onOtherChange(evt.target.value)}
              />
            )}
          </li>
        )}
      </StyledDropdownList>
    </StyledMultiSelect>
  );
};

export default MultiSelect;
