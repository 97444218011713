export default {
  'dont_have_an_account': '<0>Don\'t have an account? </0><1>Sign Up Now</1>',
  'invalid_username_or_password': 'Invalid username or password',
  'email_not_confirmed': '<0>Your email has not been confirmed yet, please check your email for a confirmation link. </0><1>Resend Confirmation Email</1>',
  'email_verified': 'Your email has been verified, please sign in to continue.',
  'email_verification_failed': '<0>There was an error verifying your email</0><1>Resend Confirmation Email</1>',
  'forgot_password': 'Forgot Password',
  'sign_in': 'Sign In',
  'sign_up_complete': 'You have successfully signed up, please verify your email and sign in to continue.',
  'unknown_error': 'There was an error signing in, please try again.',
  'welcome_back': 'Welcome back,\nplease sign in below.',
};
